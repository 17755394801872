define("discourse/plugins/discourse-theme-install-button/discourse/components/modal/theme-install", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _ajax, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "theme_install_button.modal_title"}}
    class="install-button-modal"
  >
    {{#if this.errors}}
      <div class="alert alert-error">{{this.errors}}</div>
    {{/if}}
  
    <label for="install-theme-input">{{i18n "theme_install_button.label"}}</label>
    <div class="inputs">
      <input
        type="text"
        id="install-theme-input"
        autofocus="autofocus"
        autocorrect="off"
        autocapitalize="off"
        placeholder={{i18n "theme_install_button.placeholder"}}
        value={{this.targetSite}}
        {{on "keyup" this.onKeyUp}}
        {{on "input" this.onChangeTargetInput}}
      />
      {{d-button
        class="btn-primary"
        disabled=this.disabledButton
        label="theme_install_button.install"
        action=(action "install")
      }}
    </div>
  
    <details>
      <summary>
        {{i18n "theme_install_button.manual_instructions"}}
      </summary>
      <ul>
        <p>{{html-safe (i18n "theme_install_button.instructions_before")}}</p>
        <p><code>{{this.model.repoUrl}}</code></p>
        <p>{{html-safe (i18n "theme_install_button.instructions_after")}}</p>
      </ul>
    </details>
  </DModal>
  */
  {
    "id": "UjU+QFYk",
    "block": "[[[8,[39,0],[[24,0,\"install-button-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"theme_install_button.modal_title\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"errors\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,[30,0,[\"errors\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"install-theme-input\"],[12],[1,[28,[35,1],[\"theme_install_button.label\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"inputs\"],[12],[1,\"\\n    \"],[11,\"input\"],[24,1,\"install-theme-input\"],[24,\"autofocus\",\"autofocus\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[16,\"placeholder\",[28,[37,1],[\"theme_install_button.placeholder\"],null]],[16,2,[30,0,[\"targetSite\"]]],[24,4,\"text\"],[4,[38,6],[\"keyup\",[30,0,[\"onKeyUp\"]]],null],[4,[38,6],[\"input\",[30,0,[\"onChangeTargetInput\"]]],null],[12],[13],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"class\",\"disabled\",\"label\",\"action\"],[\"btn-primary\",[30,0,[\"disabledButton\"]],\"theme_install_button.install\",[28,[37,8],[[30,0],\"install\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"details\"],[12],[1,\"\\n    \"],[10,\"summary\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"theme_install_button.manual_instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,2],[12],[1,[28,[35,13],[[28,[37,1],[\"theme_install_button.instructions_before\"],null]],null]],[13],[1,\"\\n      \"],[10,2],[12],[10,\"code\"],[12],[1,[30,0,[\"model\",\"repoUrl\"]]],[13],[13],[1,\"\\n      \"],[10,2],[12],[1,[28,[35,13],[[28,[37,1],[\"theme_install_button.instructions_after\"],null]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"if\",\"div\",\"label\",\"input\",\"on\",\"d-button\",\"action\",\"details\",\"summary\",\"ul\",\"p\",\"html-safe\",\"code\"]]",
    "moduleName": "discourse/plugins/discourse-theme-install-button/discourse/components/modal/theme-install.hbs",
    "isStrictMode": false
  });
  class ThemeInstallModal extends _component.default {
    targetSite = null;
    isLoading = false;
    errors = null;
    disabledButton(target, isLoading, errors) {
      return !target || isLoading || errors;
    }
    static #_ = (() => dt7948.n(this.prototype, "disabledButton", [(0, _decorators.default)("targetSite", "isLoading", "errors")]))();
    onKeyUp(event) {
      if (event.keyCode === 13 && this.targetSite && !this.errors && !this.isLoading) {
        this.install();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "onKeyUp", [_object.action]))();
    onChangeTargetInput(event) {
      const value = event.target.value;
      this.setProperties({
        targetSite: value,
        errors: false
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeTargetInput", [_object.action]))();
    install() {
      this.set("isLoading", true);
      const targetRoot = this.targetSite.match(/^https?:\/\//) ? this.targetSite : `https://${this.targetSite}`;
      const params = `repoName=${this.model.repoName}&repoUrl=${encodeURIComponent(this.model.repoUrl)}`;
      return (0, _ajax.ajax)(`/theme-install-checker.json`, {
        type: "POST",
        data: {
          target: targetRoot
        }
      }).then(json => {
        if (json && json.success) {
          this.set("isLoading", false);
          this.set("errors", false);
          window.location.href = `${targetRoot}/admin/customize/themes?${params}`;
        }
      }).catch(e => {
        if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
          this.set("errors", e.jqXHR.responseJSON.errors);
          this.set("isLoading", false);
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "install", [_object.action]))();
  }
  _exports.default = ThemeInstallModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ThemeInstallModal);
});